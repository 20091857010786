@import "./colors";
// Fixes

.animated-card {
  animation: createCard 0.2s;
  transform-origin: top left;
  margin-bottom: 0;
  min-height: 88vh;
  max-height: 88vh;
  overflow: auto;
}

@keyframes createCard {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.react-datepicker-wrapper .react-datepicker__close-icon::after {
  display: flex;
  cursor: pointer;
  background-color: $All_Primary;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 1px;
  font-size: 12px;
  line-height: 1;
  content: "×";
  justify-content: center;
  align-content: center;
}

.react-datepicker__day--weekend {
  color: sienna !important;
}

.react-datepicker__day--disabled {
  color: lightgray !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $All_Primary !important;
  color: $White !important;
}

td {
  padding: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eee;
  color: #555;
}

.table-responsive {
  overflow-x: visible;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

// Fix style for inline table select menu
.css-26l3qy-menu {
  z-index: 9999 !important;
}

// Fix style for inline table select menu
td .css-2b097c-container .css-26l3qy-menu {
  position: static !important;
  overflow-y: auto !important;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999 !important;
}
