@import "./colors";

::-webkit-scrollbar {
  height: 0.5em;
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: $All_Primary;
  border-radius: 7px;
}

.logo-container {
  width: 95%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  height: auto;
  align-self: center;
}

.sidebar-logo {
  width: 100%;
  height: auto;
  align-self: center;
}

.no-resize {
  resize: none;
}

.invis-create-btn,
.invis-create-btn:focus,
.invis-create-btn:active:focus {
  outline: none;
  box-shadow: none;
  color: #2eb85c;
}

.invis-btn,
.invis-btn:focus,
.invis-btn:active:focus {
  padding: 5px;
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
}

.invis-create-btn:hover {
  color: #208040;
}

// Loading spinner

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.c-sidebar-nav-title {
  margin-top: 0px;
}

.c-sidebar {
  position: relative;
  background: $Primary;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: $White;
}

.c-header {
  max-height: 50px;
}

.c-header-nav {
  position: absolute;
  right: 0;
}

.c-sidebar-nav-dropdown {
  transition: background 0.001s none;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: $Primary;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: $Tertiary;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: $Tertiary;
}

.mobileMenuButton {
  color: white;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}

.mobileMenuButton:hover {
  background: $Tertiary;
}

// Checkbox
.ak-checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.ak-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.ak-checkbox-label .ak-checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid $Primary;
}

.ak-checkbox-label input:checked ~ .ak-checkbox-custom {
  background-color: $Primary;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid $Primary;
}

.ak-checkbox-label .ak-checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid $White;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.ak-checkbox-label input:checked ~ .ak-checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid $White;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.ak-checkbox-label .ak-checkbox-custom::before {
  pointer-events: none;
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid $Primary;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.ak-checkbox-label input:disabled ~ .ak-checkbox-custom {
  background-color: gray;
  border-color: gray;
}

.ak-checkbox-label input:checked ~ .ak-checkbox-custom::before {
  pointer-events: none;
  left: 3px;
  top: 2px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.ak-checkbox-button {
  color: $White;
  background-color: $Primary;
}

.ak-checkbox-button:hover {
  color: $White;
  background-color: $Secondary;
}

//** Progress bar logic */
.progress-bar {
  background-color: #fefefe;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  margin: 15rem auto;
  min-height: 30px;
  width: 90%;
  max-width: 100%;
}

.progress {
  background: #ad5389;
  background: -webkit-linear-gradient(
    to bottom,
    $Paint_Tertiary,
    $Paint_Primary
  );
  background: linear-gradient(to bottom, $Paint_Tertiary, $Paint_Primary);
  border-radius: 3px;
  height: 30px;
  width: 50%;
  transition: width 0.5s ease-in;
}
//** Progress bar logic */

// Media

@media (max-width: 455px) {
  .breadcrumb {
    display: none;
  }
}

@media (min-width: 992px) {
  .mobileMenuButton {
    display: none;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.akp-btn-primary {
  color: $White;
  background-color: $Primary;
}

.akp-btn-primary-paint {
  color: $White;
  background-color: $Paint_Primary;
}

.akp-btn-primary-clean {
  color: $White;
  background-color: $Clean_Primary;
}

.akp-btn-primary-shampoo {
  color: $White;
  background-color: $Shampoo_Primary;
}

.akp-btn-primary-all {
  color: $White;
  background-color: $All_Primary;
}

.akp-btn-secondary {
  color: $White;
  background-color: $Secondary;
}

.akp-btn-secondary-paint {
  color: $White;
  background-color: $Paint_Secondary;
}

.akp-btn-secondary-clean {
  color: $White;
  background-color: $Clean_Secondary;
}

.akp-btn-secondary-shampoo {
  color: $White;
  background-color: $Shampoo_Secondary;
}

.akp-btn-secondary-all {
  color: $White;
  background-color: $All_Secondary;
}

.akp-btn-tertiary {
  color: $White;
  background-color: $Tertiary;
}

.akp-btn-tertiary-paint {
  color: $White;
  background-color: $Paint_Tertiary;
}

.akp-btn-tertiary-clean {
  color: $White;
  background-color: $Clean_Tertiary;
}

.akp-btn-tertiary-shampoo {
  color: $White;
  background-color: $Shampoo_Tertiary;
}

.akp-btn-tertiary-all {
  color: $White;
  background-color: $All_Tertiary;
}

input[type="text"]:disabled {
  background: #eee;
  color: #555;
}

.akp-btn-primary:hover,
.akp-btn-primary:active,
.akp-btn-primary:focus {
  background-color: $Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-secondary:hover,
.akp-btn-secondary:active,
.akp-btn-secondary:focus {
  background-color: $Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-tertiary:hover,
.akp-btn-tertiary:active,
.akp-btn-tertiary:focus {
  background-color: $Secondary;
  color: $White;
  box-shadow: none;
}

.akp-btn-primary-paint:hover,
.akp-btn-primary-paint:active,
.akp-btn-primary-paint:focus {
  background-color: $Paint_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-secondary-paint:hover,
.akp-btn-secondary-paint:active,
.akp-btn-secondary-paint:focus {
  background-color: $Paint_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-tertiary-paint:hover,
.akp-btn-tertiary-paint:active,
.akp-btn-tertiary-paint:focus {
  background-color: $Paint_Secondary;
  color: $White;
  box-shadow: none;
}

.akp-btn-primary-clean:hover,
.akp-btn-primary-clean:active,
.akp-btn-primary-clean:focus {
  background-color: $Clean_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-secondary-clean:hover,
.akp-btn-secondary-clean:active,
.akp-btn-secondary-clean:focus {
  background-color: $Clean_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-tertiary-clean:hover,
.akp-btn-tertiary-clean:active,
.akp-btn-tertiary-clean:focus {
  background-color: $Clean_Secondary;
  color: $White;
  box-shadow: none;
}

.akp-btn-primary-shampoo:hover,
.akp-btn-primary-shampoo:active,
.akp-btn-primary-shampoo:focus {
  background-color: $Shampoo_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-secondary-shampoo:hover,
.akp-btn-secondary-shampoo:active,
.akp-btn-secondary-shampoo:focus {
  background-color: $Shampoo_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-tertiary-shampoo:hover,
.akp-btn-tertiary-shampoo:active,
.akp-btn-tertiary-shampoo:focus {
  background-color: $Shampoo_Secondary;
  color: $White;
  box-shadow: none;
}

.akp-btn-primary-all:hover,
.akp-btn-primary-all:active,
.akp-btn-primary-all:focus {
  background-color: $All_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-secondary-all:hover,
.akp-btn-secondary-all:active,
.akp-btn-secondary-all:focus {
  background-color: $All_Tertiary;
  color: $White;
  box-shadow: none;
}

.akp-btn-tertiary-all:hover,
.akp-btn-tertiary-all:active,
.akp-btn-tertiary-all:focus {
  background-color: $All_Secondary;
  color: $White;
  box-shadow: none;
}

.ak-custom-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
}

.ak-custom-input:focus,
.ak-custom-input:focus-visible,
.ak-custom-input:focus-within,
.ak-custom-input:active {
  border: 1px solid black !important;
  box-shadow: none;
}

.form-control:focus {
  border: 1px solid black !important;
  box-shadow: none;
}

.css-yk16xz-control {
  background-color: transparent !important;
}

.css-1pahdxg-control:focus,
.css-1pahdxg-control:focus-visible,
.css-1pahdxg-control:focus-within,
.css-1pahdxg-control:active,
.css-1pahdxg-control:hover {
  border: 1px solid black !important;
  box-shadow: none;
}

.ak-sticky-header {
  position: -webkit-sticky; // Safari
  position: sticky;
  top: 0;
  z-index: 700;
  display: flex;
  justify-content: space-between;
}

.Toastify__toast--error {
  background: #121212 !important;
}

.Toastify__toast--success {
  background: #121212 !important;
}

.Toastify__progress-bar--success {
  background: #07bc0c !important;
}

.Toastify__progress-bar--error {
  background-color: #e74c3c !important;
}

/* The container */
.ak-radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.ak-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.ak-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.ak-radio-container:hover input ~ .ak-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.ak-radio-container input:checked ~ .ak-radio {
  background-color: $Primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.ak-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.ak-radio-container input:checked ~ .ak-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.ak-radio-container .ak-radio:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
