// Common
$Black: rgb(0, 0, 0);
$White: rgb(255, 255, 255);

// Dark Theme
$Dark: #121212;
$WhiteOverlay8: rgba(255, 255, 255, 0.8);
$WhiteOverlay4: rgba(255, 255, 255, 0.4);
$WhiteOverlay01: rgba(255, 255, 255, 0.1);
$WhiteOverlay05: rgba(255, 255, 255, 0.05);

// Primary
$Primary: #373737;
$Secondary: #2b2b2b;
$Tertiary: #020202;
$PrimaryTransparent: rgba(99, 99, 99, 0.33);
$Light: $White;

/* Blues
$Primary: rgb(31, 78, 120);
$Secondary: rgb(43, 103, 155);
$Tertiary: rgb(20, 59, 94);
$PrimaryTransparent: rgba(31, 78, 120, 0.2);
$Light: rgb(109, 159, 213);*/

/* Greens
$Primary: #2a7445;
$Secondary: #1f5533;
$Tertiary: #1a492b;
$PrimaryTransparent: rgba(38, 70, 83, 0.2);
$Light: #3dae66;*/

/*******************AKTable Themes*******************/
// Paint Division
$Paint_Primary: rgb(124, 52, 52);
$Paint_Secondary: rgb(100, 45, 45);
$Paint_Tertiary: rgb(73, 19, 19);
$Paint_Bright: rgb(187, 44, 44);
$Paint_PrimaryTransparent: rgba(208, 89, 89, 0.4);
$Paint_Light: rgb(255, 222, 222);
$Paint_WorkDay: rgb(184, 105, 105);

// Shampoo Division
$Shampoo_Primary: rgb(31, 78, 120);
$Shampoo_Secondary: rgb(43, 103, 155);
$Shampoo_Tertiary: rgb(20, 59, 94);
$Shampoo_Bright: rgb(40, 126, 201);
$Shampoo_PrimaryTransparent: rgba(104, 170, 228, 0.412);
$Shampoo_Light: rgb(230, 245, 255);
$Shampoo_WorkDay: rgb(119, 152, 181);

// Clean Division
$Clean_Primary: #2a7445;
$Clean_Secondary: #1f5533;
$Clean_Tertiary: #1a492b;
$Clean_Bright: #37a15e;
$Clean_PrimaryTransparent: rgba(98, 209, 98, 0.33);
$Clean_Light: #efffe8;
$Clean_WorkDay: #74b58c;

// All Division
$All_Primary: #373737;
$All_Secondary: #2b2b2b;
$All_Tertiary: #020202;
$All_Bright: #8a8a8a;
$All_PrimaryTransparent: rgba(99, 99, 99, 0.33);
$All_Light: $White;
